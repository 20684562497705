import { ArrowIosDownwardOutline } from '@easy-eva-icons/react'
import { CollapsibleContent, Root, Trigger } from '@radix-ui/react-collapsible'
import cn from 'classnames'
import React, { FC, ReactNode, useEffect, useState } from 'react'

type CollapsibleProps = {
  label: ReactNode
  defaultOpen?: boolean
  className?: string
  triggerClassName?: string
  scrollable?: boolean
}

export const Collapsible: FC<CollapsibleProps> = ({
  label,
  defaultOpen = false,
  className,
  children,
  triggerClassName,
  scrollable,
}) => {
  const [open, setOpen] = useState(defaultOpen)
  const [animate, setAnimate] = useState(defaultOpen)
  const [forceMount, setForceMount] = useState<true | undefined>(
    defaultOpen ? true : undefined
  )

  useEffect(() => {
    // We disable animation on mount to avoid an initial closing animation
    if (open && !animate) setAnimate(true)

    // We make the content stick around for the closing animation,
    // but unmount it after that to avoid it receiving focus
    if (open) setForceMount(true)
    else setTimeout(() => setForceMount(undefined), 300)
  }, [open, animate])

  return (
    <Root
      open={open}
      onOpenChange={(open = false) => setOpen(open)}
      className={className}
    >
      <Trigger
        type="button"
        className={cn('cursor-pointer text-link mb-2', triggerClassName)}
      >
        {label}
        <ArrowIosDownwardOutline
          className={cn('ml-1 text-gray-600 w-4 h-4 transform transition', {
            'rotate-180': open,
          })}
        />
      </Trigger>
      <CollapsibleContent
        forceMount={forceMount}
        className={cn(
          'transition-all -mx-4 px-4',
          scrollable ? 'overflow-y-auto' : 'overflow-y-hidden',
          {
            'animate-collapse': animate && !open,
            'max-h-0 opacity-0': !animate && !open,
            'animate-uncollapse': open,
          }
        )}
      >
        {children}
      </CollapsibleContent>
    </Root>
  )
}
